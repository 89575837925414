.cherry {
  height: 100%;
}

.cherry-shine {
  fill: rgb(var(--color-white));
}

.cherry-bg {
  fill: rgb(var(--color-red));
}

.leaf-bg {
  fill: rgb(var(--color-green));
}

.cherry-border {
  fill: rgb(var(--color-black));
}
