.header {
  position: fixed;
  display: flex;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  padding: 10px var(--size-x-padding);
  height: var(--size-y-header);
  z-index: 100;
  background-color: rgba(var(--color-background), 1);
}

.header .background {
  position: absolute;
  left: 0px;
  top: 0px;
  height: var(--size-y-header);
  width: 100vw;
  backdrop-filter: blur(4px);
  z-index: 1;
}

.header .home-link {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 80px;
}

.header .logo {
  position: relative;
  height: 80%;
  width: 80px;
  box-sizing: border-box;
  z-index: 10;
}

.header .logo-text {
  padding-left: 15px;
  font-size: 42px;
  font-weight: 600;
  font-family: poltawski;
  z-index: 10;
  text-decoration: none;
  color: rgb(var(--color-text));
}

.header .filler {
  flex-grow: 1;
  height: 10px;
  z-index: 10;
}

.header .sections-show {
  display: none;
  position: relative;
  width: 50px;
  text-align: center;
  font-size: 40px;
  cursor: pointer;
  z-index: 10;
}
.header .sections-show .MuiSvgIcon-root {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.header .sections-close {
  display: none;
}

.header .sections {
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: 60px;
  z-index: 10;
}

.header a {
  text-decoration: none;
}

.header .sections .section a {
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  color: rgb(var(--color-text));
  transition: color 0.2s;
}
.header .sections .section:hover a {
  color: rgba(var(--color-primary), 0.6);
}
.header .sections .section.selected a {
  color: rgb(var(--color-primary));
}

.header .cl-userButton-root {
  margin: 0px 0px 0px 60px;
  z-index: 10;
}

.header .cl-avatarBox {
  height: calc(var(--size-y-header) * 0.6);
  width: calc(var(--size-y-header) * 0.6);
  border: 1px solid rgb(var(--color-primary));
}

.sign-in-button {
  position: relative;
  z-index: 10;
}

@media screen and (max-width: 1000px) {
  .header .logo {
    width: 50px;
  }

  .header .logo-text {
    padding-left: 10px;
    font-size: 28px;
  }

  .header .section-show-filler {
    flex-grow: 1;
  }

  .header .sections-show {
    display: block;
  }

  .header .sections-close {
    position: absolute;
    display: block;
    top: 40px;
    right: 40px;
    color: white;
    font-size: 46px;
  }

  .header .sections {
    position: absolute;
    flex-direction: column;
    gap: 30px;
    top: 0px;
    left: -100vw;
    width: 100vw;
    height: 100vh;
    padding: 20vh 15vw;
    box-sizing: border-box;
    margin-top: 0px;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(6px);
    z-index: 100;
    transition: all 0.5s;
  }
  .header .sections.show {
    left: 0px;
  }

  .header .sections .section a {
    color: white;
  }
  .header .sections .section.selected a {
  }

  .header .sections .button {
    background-color: transparent;
  }

  .header .sections a.active .section {
    color: rgb(var(--color-primary));
  }

  .header .cl-userButton-root {
    margin: 0px 0px 0px 20px;
  }
}
