.career-experience {
  padding: 10px 15px;
  border-radius: 5px;
  border: 0.5px solid rgb(var(--color-primary));
  transition: all, 0.2s;

  .user-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 500;

    .profile-image {
      position: relative;
      flex-shrink: 0;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid rgb(var(--color-primary));
      overflow: hidden;
      margin-right: 15px;
    }

    .details {
      .user-name {
        display: flex;
        align-items: center;
        font-size: 16px;
        margin-bottom: 2px;
        gap: 10px;

        a {
          display: inline-flex;
          align-items: center;
          gap: 5px;
          text-decoration: none;
          color: rgb(var(--color-primary));
          background-color: rgba(var(--color-primary), 0.1);
          padding: 2px 4px;
          border-radius: 4px;

          img {
            height: 18px;
            width: 18px;
          }
        }

        .secondary-career-name {
          color: rgb(var(--color-primary));
        }
      }
    }

    .career-length {
      font-size: 16px;
      opacity: 0.6;
    }
  }

  .description {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .questions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    border-top: 0.5px solid rgb(var(--color-primary));
    padding-top: 10px;
    padding-bottom: 20px;

    .question {
      flex: 1;
      min-width: 200px;

      .question-title {
        font-size: 14px;
        opacity: 0.6;
        margin-bottom: 10px;
      }

      .question-answer {
        font-size: 16px;
      }
    }
  }

  .comments {
    .comments-inner {
      padding: 15px 0;

      .new-comment {
        display: flex;
        gap: 20px;
      }

      .comments-list {
        padding-left: 20px;

        .comment {
          display: flex;
          align-items: flex-start;
          margin-top: 20px;

          .user-image {
            position: relative;
            flex-shrink: 0;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 1px solid rgb(var(--color-primary));
            overflow: hidden;
            margin-right: 15px;
          }

          .user-name {
            margin-bottom: 5px;
            font-size: 16px;
            font-weight: 600;
          }

          .comment-content {
            .comment-text {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .career-experience { 
    .user-info {
      align-items: start;

      .details {
        .user-name {
          flex-direction: column;
          align-items: start;
          gap: 5px;
          margin-bottom: 5px;
        }
      }
    }

    .description {
      font-size: 14px;
    }

    .comments-inner {
      .comments-list {
       padding-left: 5px;
      }
    }    
  }
}
