.user-answers-view {
  padding: 20px var(--size-x-padding) 40px var(--size-x-padding);

  .page-title {
    display: flex;
    align-items: center;

    a {
      display: inline-flex;
      align-items: center;
      gap: 5px;
      margin-left: 10px;
      text-decoration: none;
      color: rgb(var(--color-primary));
      background-color: rgba(var(--color-primary), 0.1);
      padding: 2px 4px;
      border-radius: 4px;
  
      img {
        height: 18px;
        width: 18px;
      }
    }
  }
}

.user-answers-view .profile-image {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  flex-shrink: 0;
  border: 1px solid rgb(var(--color-primary));
  overflow: hidden;
  margin-right: 15px;
}

.user-answers-view .career-experience {
  margin-bottom: 30px;
}
