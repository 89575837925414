@font-face {
  font-family: poltawski;
  src: url("assets/PoltawskiNowy.ttf");
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(0.98);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slide-appear {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@font-face {
  font-family: lato;
  src: url("assets/Lato-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: lato;
  src: url("assets/Lato-Medium.ttf");
  font-weight: 500;
}

:root {
  /* Define general colors */
  --color-red: 192, 32, 50;
  --color-green: 13, 127, 20;
  --color-white: 255, 255, 255;
  --color-offwhite: 245, 242, 238;
  --color-offwhite-2: 254, 254, 249;
  /* --color-black: 31, 33, 43; */
  --color-black: 51, 3, 0;

  /* Define color palette */
  --color-background: var(--color-offwhite);
  --color-text: var(--color-black);
  --color-primary: var(--color-red);
  --color-secondary: var(--color-green);

  /* Define sizes */
  --size-x-small-screen: 600px;
  --size-x-padding: 80px;
  --size-y-header: 120px;
}

@media screen and (max-width: 600px) {
  :root {
    --size-x-padding: 40px;
    --size-y-header: 80px;
  }
}

body {
  margin: 0;
  font-family: lato;
  color: rgb(var(--color-text));
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(var(--color-background));
}

.ml-1 {
  margin-left: 5px !important;
}
.ml-2 {
  margin-left: 10px !important;
}
.ml-3 {
  margin-left: 15px !important;
}
.ml-4 {
  margin-left: 20px !important;
}

.mt-1 {
  margin-top: 5px !important;
}
.mt-2 {
  margin-top: 10px !important;
}
.mt-3 {
  margin-top: 15px !important;
}
.mt-4 {
  margin-top: 20px !important;
}

.no-break {
  white-space: nowrap;
}

.anim-appear {
  animation-name: appear;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
}

.anim-slide-appear {
  animation-name: slide-appear;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
}
