.motivation-view {
  padding: 20px var(--size-x-padding) 40px var(--size-x-padding);
  margin: 0px auto;
  max-width: 800px;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.motivation-view .slogan {
  text-align: center;
  margin-bottom: 30px;
  font-weight: 500;
  font-style: italic;
}

.motivation-view p {
  margin-bottom: 20px;
}

.motivation-view .highlighted {
  font-weight: 500;
  color: rgb(var(--color-primary));
}

.motivation-view .who-we-are {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-top: 60px;
}

.motivation-view .person {
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 20px;
}

.motivation-view .person .photo {
  position: relative;
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid rgb(var(--color-primary));
  overflow: hidden;
}

.motivation-view .person .photo img {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
}
.motivation-view .person .soniya-photo img {
  transform: translateX(-50%) translateY(-40%);
}

.motivation-view .person .info .name {
  font-size: 32px;
  margin-bottom: 12px;
}
.motivation-view .person .info .role {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 18px;
  opacity: 0.7;
  margin-bottom: 5px;
}
.motivation-view .person .info .location {
  display: flex;
  align-items: center;
  gap: 20px;
  opacity: 0.7;
  font-size: 18px;
}

@media screen and (max-width: 600px) {
  .motivation-view {
    font-size: 18px;
  }
  .motivation-view .person {
    flex-direction: column;
  }
  .motivation-view .person .info {
    text-align: center;
  }
  .motivation-view .person .photo {
    width: 60px;
    height: 60px;
  }
  .motivation-view .person .info .name {
    font-size: 22px;
  }
  .motivation-view .person .info .role {
    justify-content: start;
    align-items: start;
    text-align: left;
    margin-bottom: 15px;
  }
  .motivation-view .person .info .location {
    justify-content: start;
    align-items: start;
    text-align: left;
  }
}
