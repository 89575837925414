@keyframes bounce {
  0% {
    transform: translateY(0%);
  }
  33% {
    transform: translateY(-100%);
  }
  66% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(0%);
  }
}

.loader {
  --animation-duration: 0.7s;
  display: inline-block;
  white-space: nowrap;
}

.loader .dot {
  display: inline-block;
  height: 0.4em;
  width: 0.4em;
  border-radius: 50%;
  opacity: 0.8;
  background-color: currentColor;
  animation-name: bounce;
  animation-duration: var(--animation-duration);
  animation-iteration-count: infinite;
}

.loader .dot:nth-of-type(2) {
  margin-left: 0.3em;
  animation-delay: calc(var(--animation-duration) / 5);
}

.loader .dot:nth-of-type(3) {
  margin-left: 0.3em;
  animation-delay: calc(var(--animation-duration) / 5 * 2);
}
