.user-square {
  position: relative;
  height: 173px;
  width: 140px;
}

.user-square .picture {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.1);
  height: 140px;
  width: 140px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.2s;
}
.user-square .picture:hover {
  transform: scale(1.05);
}

.user-square .picture .background {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  transition: all 0.2s;
}
.user-square .picture:hover .background {
  transform: scale(1.05);
}

.user-square .picture .click-here {
  position: absolute;
  display: flex;
  align-items: end;
  justify-content: center;
  width: 100%;
  height: 25%;
  bottom: 0px;
  box-sizing: border-box;
  color: white;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  padding: 0px 10px 6% 10px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.user-square .name {
  position: absolute;
  display: flex;
  align-items: top;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  bottom: 0px;
  height: 20px;
  width: 100%;
  font-size: 12px;

  .title {
    color: rgb(var(--color-primary));
  }
}

@media screen and (max-width: 1000px) {
  .user-square {
    height: 130px;
    width: 100px;
  }

  .user-square .picture {
    width: 100px;
    height: 100px;
  }

  .user-square .picture .click-here {
    font-size: 10px;
  }

  .user-square .name {
    font-size: 10px;
  }
}
