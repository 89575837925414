.contact-view {
  padding: 20px var(--size-x-padding) 40px var(--size-x-padding);
  margin: 0px auto;
  max-width: 800px;
  align-items: center;
  justify-content: center;
}

.contact-view p {
  font-size: 24px;
  margin-top: 0px;
}

.contact-view .highlighted {
  font-weight: 500;
  color: rgb(var(--color-primary));
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 30px;
}

.contact-form .name {
  display: flex;
  width: 100%;
  gap: 30px;
}

@media screen and (max-width: 600px) {
  .contact-view p {
    font-size: 18px;
  }
  .contact-form .name {
    flex-direction: column;
  }
}
