.user-career-experience {
  transition: all, 0.2s;
}

.career-experience-form .required {
  color: rgb(var(--color-primary));
  font-weight: 600;
}

.career-experience-form .label {
  opacity: 0.7;
  margin-bottom: 5px;
}

.career-experience-form .label .highlited {
  font-weight: 600;
  color: rgb(var(--color-primary));
}

.career-experience-form .MuiInputBase-root {
  background-color: rgb(var(--color-background));
}

.career-experience-form .MuiFormHelperText-root {
  color: #d32f2f;
  margin-top: 4px;
  margin-left: 14px;
}

.career-experience-form .helper-text {
  opacity: 0.4;
  font-style: normal;
}

.career-experience-form .add-career-blocks {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.career-experience-form .explanation-text {
  display: flex;
  align-items: start;
  margin-top: 0px;
  font-size: 14px;
  gap: 10px;
  opacity: 0.4;
}
.career-experience-form .explanation-text div {
  margin-top: 1px;
}

.career-experience-form .add-career-block {
  min-width: min(100%, 300px);
}

.career-experience-form .add-career-description {
  margin-bottom: 20px;
}

.career-experience-form .add-career-questions {
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  gap: 20px;
}

.career-experience-form .add-career-question {
  flex: 1;
  min-width: min(100%, 300px);
  box-sizing: border-box;
}

.career-experience-form .add-career-question .label {
  font-weight: 600;
  font-size: 18px;
}

.career-experience-form .form-footer {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 0.5px solid rgb(var(--color-red));
}

.user-career-experience .career-info {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
}

.user-career-experience .career-info .edit {
  position: absolute;
  right: 0px;
  cursor: pointer;
  color: rgb(var(--color-red));
}

.user-career-experience .career-name {
  font-size: 20px;
  color: rgb(var(--color-red));
  font-weight: 500;
}

.user-career-experience .career-length {
  opacity: 0.8;
  font-size: 16px;
}

.user-career-experience .career-description {
  margin-top: 8px;
  font-size: 16px;
}

.user-career-experience .questions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  border-top: 0.5px solid rgb(var(--color-primary));
  padding-top: 10px;
}

.user-career-experience .question {
  flex: 1;
  min-width: 200px;
}

.user-career-experience .question-title {
  font-size: 14px;
  opacity: 0.6;
  margin-bottom: 10px;
}

.user-career-experience .question-answer {
  font-size: 16px;
}

@media screen and (max-width: 1000px) {
  .user-career-experience .career-description {
    font-size: 14px;
  }
}
