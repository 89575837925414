.my-careers-view {
  padding: 20px var(--size-x-padding) 40px var(--size-x-padding);

  .my-careers-sign-in {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 60px;
    margin-top: 25px;

    .thank-you {
      font-size: 24px;
      width: 60%;
      text-align: center;
    }
  }

  h2 {
    color: rgb(var(--color-primary));
  }

  .personal-information-box {
    margin-bottom: 25px;

    .label {
      display: flex;
      align-items: center;
      gap: 5px;
      opacity: 0.7;
      margin-bottom: 5px;

      img {
        width: 15px;
        height: 15px;
      }
    }

    .enter-url-container {
      width: 600px;
      max-width: 100%;

      .MuiFormControl-root {
        width: 100%;

        .MuiInputBase-root {
          width: 100%;

          input {
            padding: 8px 10px;
            width: 100%;
          }
        }
      }
    }
  }

  .experience {
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 5px;
    border: 0.5px solid rgb(var(--color-primary));
  }
}

@media screen and (max-width: 1000px) {
  .my-careers-view .my-careers-sign-in {
    gap: 30px;
  }
  .my-careers-view .my-careers-sign-in .thank-you {
    width: 80%;
    font-size: 18px;
  }
}
