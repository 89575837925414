.share-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.platform-share-buttons-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;

    .platform-share-button {
        display: flex;
        align-items: center;
        gap: 5px;
        background-color: rgba(var(--color-primary), 0.08);
        color: rgb(var(--color-primary));
        font-weight: 500;
        padding: 2px 10px 2px 2px;
        border-radius: 15px;
        transition: all, 0.3s;

        svg {
            width: 30px;
            height: 30px;
            border-radius: 15px;
        }

        &:hover {
            background-color: rgba(var(--color-primary), 0.12);
            transform: scale(1.025);
        }
    }
}