.about-view {
  padding: 20px var(--size-x-padding) 40px var(--size-x-padding);
  margin: 0px auto;
  max-width: 800px;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.about-view .home-section {
  margin-bottom: 55px;
}

.about-view h2 {
  font-size: 34px;
  font-weight: 700;
  margin: 0px;
  margin-bottom: 20px;
}

.about-view p {
  margin: 0px;
}

.about-view .highlight {
  font-weight: 500;
  color: rgb(var(--color-primary));
}

@media screen and (max-width: 600px) {
  .about-view .home-section {
    margin-bottom: 30px;
  }
  .about-view h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .about-view p,
  .about-view li {
    font-size: 16px;
  }
  .about-view {
    font-size: 22px;
  }
  .about-view .people {
    margin-top: 50px;
  }
}
