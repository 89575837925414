.explore-career-view {
  padding: 20px var(--size-x-padding) 40px var(--size-x-padding);
}

.count {
  opacity: 0.4;
  font-weight: 500;
}

.favourite-icon {
  color: rgb(var(--color-primary));
  cursor: pointer;
  transition: all, 0.2s;
}
.favourite-icon:hover {
  transform: scale(1.1);
}

.explore-career-view .career-experience {
  margin-bottom: 30px;
}
