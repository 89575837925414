@keyframes minimise-box {
  0% {
    max-height: 1500px;
  }
  100% {
    max-height: 0px;
  }
}

@keyframes maximise-box {
  0% {
    max-height: 0px;
  }
  100% {
    max-height: 1500px;
  }
}

@keyframes hide-box {
  0% {
    opacity: 1;
    max-height: 1500px;
    transform: scale(1);
    margin-bottom: 35px;
  }
  50% {
    opacity: 0;
    max-height: 1500px;
    transform: scale(0.98);
    margin-bottom: 35px;
  }
  100% {
    opacity: 0;
    max-height: 0px;
    transform: scale(0.98);
    margin-bottom: 0px;
  }
}

@keyframes show-box {
  0% {
    opacity: 0;
    max-height: 0px;
    transform: scale(0.98);
    margin-bottom: 0px;
  }
  50% {
    opacity: 0;
    max-height: 1500px;
    transform: scale(0.98);
    margin-bottom: 35px;
  }
  100% {
    opacity: 1;
    max-height: 1500px;
    transform: scale(1);
    margin-bottom: 35px;
  }
}

.app-body {
  width: 100svw;
  height: 100svh;
  padding-top: var(--size-y-header);
  box-sizing: border-box;
}

.page-title {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.page-title h1 {
  font-size: 30px;
  margin: 0px;
  color: rgb(var(--color-red));
}

.flex-filler {
  flex: 1;
}

@media screen and (max-width: 1500px) {
  .page-title h1 {
    font-size: 24px;
    padding-right: 15px;
  }
  .page-title .button-text {
    display: none;
  }
}

/* BUTTON */
.button {
  display: inline-block;
  border: 0.9px solid rgb(var(--color-primary));
  padding: 8px 15px;
  border-radius: 50px;
  color: rgb(var(--color-primary));
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;
}
.button.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.button:hover {
  background-color: rgba(var(--color-primary), 0.1);
}
.buttondisabled:hover {
}
.button.selected {
  background-color: rgb(var(--color-primary));
  color: rgb(var(--color-background));
}
.button.big {
  font-size: 24px;
}

.button-inner {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* BUTTON 2 */
.button-2 {
  display: inline-block;
  background-color: rgba(var(--color-primary), 0.08);
  color: rgb(var(--color-primary));
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: all, 0.3s;
}
.button-2.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.button-2:hover {
  background-color: rgba(var(--color-primary), 0.12);
  transform: scale(1.025);
}
.button-2.disabled:hover {
}
.button-2-inner {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.button-3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: rgb(var(--color-primary));
  height: 50px;
  padding: 0 20px;
  box-sizing: border-box;
  font-size: 18px;
  border-radius: 25px;
  font-weight: 600;
  color: rgba(var(--color-background));
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    transform: scale(1.05);
  }
}

/* BOX */
.box {
  border-radius: 10px;
  border: 1px solid rgb(var(--color-primary));
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.box.box-hidable {
  max-height: 0px;
  opacity: 0;
  margin-bottom: 0px;
}
.box.box-hidable.hidden {
  max-height: 0px;
  opacity: 0;
  animation-name: hide-box;
  animation-duration: 0.6s;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
}
.box.box-hidable.visible {
  max-height: 1500px;
  opacity: 1;
  margin-bottom: 35px;
  animation-name: show-box;
  animation-duration: 0.7s;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
}
.box.box-minimisable {
  margin-bottom: 35px;
}

.box .box-header {
  display: flex;
  align-items: center;
  padding: 10px 20px;
}
.box.box-minimisable .box-header {
  cursor: pointer;
}

.box .box-header .title-icon {
  color: rgb(var(--color-primary));
  margin-right: 10px;
  transform: translateY(3px);
}

.box .box-header .title {
  font-size: 20px;
  font-weight: 500;
  color: rgb(var(--color-primary));
}

.box-header .minimise-toggle {
  rotate: 0deg;
  transition: rotate, 0.4s;
  cursor: pointer;
}
.box.minimised .box-header .minimise-toggle {
  rotate: 180deg;
}

.box .box-body {
  padding: 20px;
  border-top: 0.5px solid rgb(var(--color-primary));
  border-bottom: 0.5px solid rgb(var(--color-primary));
}

.box .box-minimise {
  overflow: hidden;
}
.box.minimised .box-minimise {
  max-height: 0px;
  animation-name: minimise-box;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
}
.box.maximised .box-minimise {
  max-height: 1500px;
  animation-name: maximise-box;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
}

.box .box-footer {
  padding: 10px 20px;
}

.section-title {
  margin-bottom: 35px;
  margin-top: 0px;
  color: rgb(var(--color-primary));
  font-size: 42px !important;
}

@media screen and (max-width: 1000px) {
  .section-title {
    font-size: 28px !important;
    margin-bottom: 35px;
  }
}

/* Sweet alerts */

.swal2-icon {
  color: rgba(var(--color-primary), 0.4) !important;
  border-color: rgba(var(--color-primary), 0.4) !important;

  .swal2-success-line-tip {
    background-color: rgba(var(--color-primary), 0.4) !important;
  }

  .swal2-success-line-long {
    background-color: rgba(var(--color-primary), 0.4) !important;
  }

  .swal2-success-ring {
    border-color: rgba(var(--color-primary), 0.2) !important;
  }
}

.swal2-confirm {
  background-color: rgba(var(--color-primary), 0.08);
  color: rgb(var(--color-primary));
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: all, 0.3s;
  
  &:focus {
    outline: 0 !important;
  }
}

.swal2-cancel {
  background-color: rgba(var(--color-black), 0.08);
  color: rgb(var(--color-black));
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: all, 0.3s;

  &:focus {
    outline: 0 !important;
  }
}
