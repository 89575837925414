.explore-search-view {
  padding: 20px var(--size-x-padding) 40px var(--size-x-padding);
}

.liked-careers {
  display: inline-block;
  border: 1px solid rgb(var(--color-primary));
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  box-shadow:rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  margin-bottom: 25px;

  .title {
    display: flex;
    padding: 10px 15px;
    border-bottom: 1px solid rgb(var(--color-primary));
    color: rgb(var(--color-primary));

    .title-text {
      margin-left: 10px;
      font-size: 18px;
      font-weight: 500;
    }
  }

  .career-list {
    display: flex;
    flex-direction: row;
    padding: 20px 15px;
    gap: 10px;
    flex-wrap: wrap;

    a {
      text-decoration: none;
    }

    .career {
      padding: 7px 10px;
      border-radius: 5px;
      font-weight: 500;
      color: rgb(var(--color-text));
      background-color: rgba(var(--color-primary), 0.1);
      cursor: pointer;
      transition: all 0.3s;
  
      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

.HighlightedSearchText {
  color: rgb(var(--color-primary));
}

.search-bar {
  display: flex;
  align-items: center;
  padding: 25px;
  gap: 25px;
  background-color: rgba(var(--color-primary), 0.04);
  margin-bottom: 25px;
  box-shadow:rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  border-radius: 10px;

  .MuiInputAdornment-root {
    color: rgba(var(--color-primary), 0.75) !important;
  }

  .bar {
    flex-grow: 1;
  }
}

.explore-search-view a {
  text-decoration: none;
  color: rgb(var(--color-text));
}

.career-result {
  border: 0.5px solid rgb(var(--color-primary));
  padding: 10px 20px;
  margin-bottom: 20px;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent;
  transition: all, 0.2s;
  text-decoration: none;

  &:hover {
    background-color: rgba(var(--color-primary), 0.04);
    transform: scale(1.005);
  }
}

.people-container {
  h2 {
    color: rgb(var(--color-primary));
  }

  .people {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    gap: 20px;
    margin-top: 25px;
  }
}

@media screen and (max-width: 1000px) {
  .career-result {
    font-size: 18px;
  }

  .search-bar {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-bar .bar {
    width: 100%;
  }

  .people-container {
    .people {
      justify-content: center;
    }
  }
}
