.home-view {
  padding: 20px var(--size-x-padding) 40px var(--size-x-padding);
}

.landing-slogan {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.landing-slogan-inner {
    padding: 0px var(--size-x-padding);
    text-align: center;
    font-size: 52px;
    font-weight: 600;
}
.landing-slogan-inner-2 {
    padding: 0px var(--size-x-padding);
    text-align: center;
    font-size: 24px;
    font-weight: 500;
}

.landing-slogan a {
    text-decoration: none;
}

.landing-slogan .call-to-action {
    margin-bottom: 10vh;
}

.call-to-action {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: rgb(var(--color-primary));
    padding: 0px 25px;
    box-sizing: border-box;
    height: 50px;
    font-size: 18px;
    border-radius: 30px;
    font-weight: 600;
    color: rgba(var(--color-background));
    cursor: pointer;
    transition: all 0.2s;
}
.call-to-action:hover {
    transform: scale(1.05);
}

.call-to-action div {
    text-decoration: none;
    white-space: nowrap;
}

@media screen and (max-width: 1000px) {
    .landing-slogan-inner {
        font-size: 40px;
    }
    .landing-slogan-inner-2 {
        font-size: 18px;
    }
}
